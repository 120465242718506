import React, { UIEvent, useEffect, useState, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout, { ContentContext } from '../layouts'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { Link } from 'gatsby'
import { CareerContext } from '../../context/CareerContext'

const Description = ({ id }: { id: number }) => {
  const jobs = useStaticQuery(graphql`
    query jobsAndJobsAndJobs {
      allStrapiJob {
        nodes {
          localizations {
            data {
              attributes {
                locale
                form
                category
                requirements
                description
                responsibilities
                title
                type
                validity
              }
            }
          }
          form
          id
          title
          type
          category
          validity
          description {
            data {
              description
            }
          }
          requirements {
            data {
              requirements
            }
          }
          responsibilities {
            data {
              responsibilities
            }
          }
        }
      }
    }
  `)

  const AllJobs = jobs.allStrapiJob.nodes
  const { lang, setLanguage, translate } = useContext(ContentContext)
  const [job, setJob] = useState<any>()
  const [items, setItems] = useState<Array<object>>(AllJobs)
  const { currentLanguage } = useContext(CareerContext)

  useEffect(() => {
    const jobFromId = AllJobs.filter((item: any) => {
      return item.id == id
    })

    setTimeout(() => {
      setJob({ ...jobFromId[0] })
      console.log({job});
      
    }, 0)
  }, [id])

  return (
    <>
      <section
        className="wrapper"
        style={{
          backgroundImage: "url('/bg22.png')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: '100%'
        }}
      >
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <h1 className="display-1 mb-5">
                  {
                      lang === 'en'
                      ? job?.title
                      : lang === 'fr'
                        ? job?.localizations.data.map((data: any) =>{
                          if (data.attributes.locale === 'fr') {
                            return data.attributes?.title
                          }
                        })
                        : lang === 'ar'
                          ? job?.localizations.data.map((data: any) =>{
                            if (data.attributes.locale === 'ar') {
                              return data.attributes?.title
                            }
                          })
                          : job?.title
                  }
                </h1>
                <ul className="post-meta fs-17 mb-5">
                  <li>
                    <i className="uil uil-clock"></i>{' '}
                  {
                    lang === 'en'
                    ? job?.type
                    : lang === 'fr'
                      ? job?.localizations.data.map((data: any) =>{
                        if (data.attributes.locale === 'fr') {
                          return data.attributes?.type
                        }
                      })
                      : lang === 'ar'
                        ? job?.localizations.data.map((data: any) =>{
                          if (data.attributes.locale === 'ar') {
                            return data.attributes?.type
                          }
                        })
                        : job?.type
                  }
                  </li>
                  <li>
                    <i className="uil uil-building"></i>{' '}
                    {
                    lang === 'en'
                    ? job?.category
                    : lang === 'fr'
                      ? job?.localizations.data.map((data: any) =>{
                        if (data.attributes.locale === 'fr') {
                          return data.attributes?.category
                        }
                      })
                      : lang === 'ar'
                        ? job?.localizations.data.map((data: any) =>{
                          if (data.attributes.locale === 'ar') {
                            return data.attributes?.category
                          }
                        })
                        : job?.category
                  }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="blog single mt-n17">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <h2 className="h1 mb-3">{translate('description')}</h2>
                    <p>
                    {
                    lang === 'en'
                    ? job?.description?.data.description
                    : lang === 'fr'
                      ? job?.localizations.data.map((data: any) =>{
                        if (data.attributes.locale === 'fr') {
                          return data.attributes?.description
                        }
                      })
                      : lang === 'ar'
                        ? job?.localizations.data.map((data: any) =>{
                          if (data.attributes.locale === 'ar') {
                            return data.attributes?.description
                          }
                        })
                        : job?.description?.data.description
                  }
                    </p>
                    <h3 className="h2 mb-3 mt-9">
                      {translate('responsibilties')}
                    </h3>
                    <div className="row gy-3 gx-xl-8">
                      <div className="col-xl-12">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li
                            style={{ textAlign: 'justify', paddingLeft: '0px' }}
                          >
                            <span>
                              
                              <MarkdownPreview
                                style={{
                                  fontFamily: 'inherit',
                                  color: 'inherit',
                                  fontSize: '0.85rem'
                                }}
                                source={ 
                                  lang === 'en'
                                  ? job?.responsibilities.data.responsibilities
                                  : lang === 'fr'
                                    ? job?.localizations.data[0].attributes.locale == 'fr' ? 
                                    `${job?.localizations.data[0].attributes?.responsibilities}` : 
                                    `${job?.localizations.data[1].attributes?.responsibilities}`
                                    : lang === 'ar'
                                      ? job?.localizations.data[0].attributes.locale == 'ar' ? 
                                      `${job?.localizations.data[0].attributes?.responsibilities}` : 
                                      `${job?.localizations.data[1].attributes?.responsibilities}`
                                      : job?.responsibilities.data.responsibilities
                                }
                                warpperElement={{
                                  'data-color-mode': 'light'
                                }}
                              />
                             
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h3 className="h2 mb-3 mt-9">
                      {translate('requirements')}
                    </h3>
                    <div className="row gy-3 gx-xl-8 mb-10">
                      <div className="col-xl-12">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li
                            style={{ textAlign: 'justify', paddingLeft: '0px' }}
                          >
                            <MarkdownPreview
                              style={{
                                fontFamily: 'inherit',
                                color: 'inherit',
                                fontSize: '0.85rem'
                              }}
                              source={
                                lang === 'en'
                                  ? job?.requirements.data.requirements
                                  : lang === 'fr'
                                    ? job?.localizations.data[0].attributes.locale == 'fr' ? 
                                    `${job?.localizations.data[0].attributes?.requirements}` : 
                                    `${job?.localizations.data[1].attributes?.requirements}`
                                    : lang === 'ar'
                                      ? job?.localizations.data[0].attributes.locale == 'ar' ? 
                                      `${job?.localizations.data[0].attributes?.requirements}` : 
                                      `${job?.localizations.data[1].attributes?.requirements}`
                                      : job?.requirements.data.requirements
                              }
                              warpperElement={{
                                'data-color-mode': 'light'
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <a
                      href={job?.form}
                      className="btn btn-primary rounded-pill "
                    >
                      {translate('apply')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="wrapper  mb-10"
        style={{
          backgroundImage: "url('/bg22.png')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: '100%'
        }}
      >
        <div className="container py-14 py-md-16">
          <div className="row align-items-center mb-6">
            <div className="col-md-8 col-lg-9 col-xl-8 col-xxl-7 pe-xl-20">
              <h2 className="display-6 mb-0">{translate('more_job')}</h2>
            </div>
            <div className="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
              <Link
                to="/career#job"
                className="btn btn-primary rounded-pill mb-0"
              >
                {translate('explore_position')}
              </Link>
            </div>
          </div>
          <div className="row gy-6">
            {items?.map((val: any, id: any) => {
              return (
                val.validity && (
                  <div className="col-md-6 col-lg-4 ">
                    <Link
                      to={`/description/${val?.id}`}
                      key={id}
                      className="card shadow-lg lift h-100"
                    >
                      <div className="card-body p-5 d-flex flex-row">
                        <div>
                          <span className="avatar bg-red text-white w-11 h-11 fs-20 me-4">
                            <i className="uil uil-bag-alt "></i>
                          </span>
                        </div>
                        <div>
                          <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">
                            {lang === 'en'
                              ? val.type
                              : lang === 'fr'
                                ? val?.localizations.data.map((data: any) =>{
                                  if (data.attributes.locale === 'fr') {
                                    return data.attributes?.type
                                  }
                                })
                                : lang === 'ar'
                                  ? val?.localizations.data.map((data:any ) =>{
                                    if (data.attributes.locale === 'ar') {
                                      return data.attributes?.type
                                    }
                                  })
                                  : val.type}
                          </span>
                          <h4 className="mb-1">
                            {lang === 'en'
                              ? val.title
                              : lang === 'fr'
                                ? val?.localizations.data.map((data: any) =>{
                                  if (data.attributes.locale === 'fr') {
                                    return data.attributes?.title
                                  }
                                })
                                : lang === 'ar'
                                  ? val?.localizations.data.map((data:any ) =>{
                                    if (data.attributes.locale === 'ar') {
                                      return data.attributes?.title
                                    }
                                  })
                                  : val.title}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default Description

import React, { UIEvent, useEffect, useState, useContext } from 'react'
import Footer from '../../components/footer'
import Nav from '@components/nav'
import Description from './Description'

function CareerDescription({ id }: { id: number }) {

  return (
    <>
      <Nav />
      <Description id={id} />
      <Footer />
    </>
  )
}
export default CareerDescription

import React, { UIEvent, useEffect, useState, useContext } from 'react'
import Layout, { ContentContext } from '../../components/layouts'
import { CareerContext, CareerContextProvider } from '../../context/CareerContext';
import CareerDesription from '../../components/CareerDescription'



type props = {
params:{
    id:number
}
}


function Description(props:props) {
    
    return (
        <CareerContextProvider>
            <Layout>
              <CareerDesription id={props.params.id}/>
            </Layout>
        </CareerContextProvider>
    )
}
export default Description

export const Head = () => {
    return (
      <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="title" property='og:title' content="nano dev inc" />
        <meta name="type" property='og:type' content="website" />
        <meta name="image" property='og:image' content="https://ashy-sand-0c4dc6a10.2.azurestaticapps.net/nanologo.png" />
        <meta name="url" property='og:url' content="https://www.nanodev.ca" />
        <meta name="description" property='og:description' content="PROVIDE CREATIVE AND INNOVATIVE SOLUTIONS" />
        <meta property="og:site_name" content="nano dev inc"></meta>
  
        <meta
          name="keywords"
          content="IT, Software, business, corporate, creative, marketing, AI, modern, startup"
        />
        <meta name="author" content="NanoDev" />
        <title>Job-Description</title>
      </>
    )
}